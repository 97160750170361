import { useDispatch, useSelector } from "react-redux"
import React, { useReducer, useEffect, useState } from "react"
import { consolidatedToDoReducer, initialState } from "./reducer"
import { getActiveUserStory } from "./Services/getActiveUserStory";
import { getConsolidatedTodoBy, getConsolidatedTodoTo, getAllTasks } from "./Services/getConsolidatedToDo";
import { getPrivateTodo } from "./Services/getPrivateToDo";
import { updateUserStory } from "./Services/updateUserStory";
import $ from 'jquery';
import SideBar from "../SquadChat/sideNav"
import TopNavWithOutProject from "../Utility/TopNav/topnav"
import { getSubStringId } from "../../Common/SubStringConvert"
import MainTaskInfo from "../../Common/TasksModals/mainTaskInfo"
// import ModifyTemplate from "../../Common/TasksModals/modifyTemplate"
// import DeleteTemplate from "../../Common/TasksModals/deleteTemplate"
// import EditTemplate from "../../Common/TasksModals/editTemplate"
import AddPrivateToDo from "../../Common/PrivateToDo/addPrivateToDo"
import AddToToDoProjects from "../../Common/PrivateToDo/addToDoToProject"
import ModifyPrivateToDo from "../../Common/PrivateToDo/modifyPrivateToDo"
import DeletePrivateToDo from "../../Common/PrivateToDo/deletePrivateToDo"
import { getRoleCount, setRoleCount, setToken } from "../../Common/LocalStorage"
import { setCurrentUser } from "../../Common/Actions"
import API from "../../Common/Network/API"
import jwt_decode from "jwt-decode";
import { Redirect, Link } from "react-router-dom"
import moment from "moment"
import { useWebProperties } from "../../Common/webProperties";
import { moduleReducer, initialState as chatInitialState } from "../Modules/moduleReducer";
import MainTaskChatBox from "../../Common/ChatMainTask";
import ModifyMainTask from "../../Common/TasksModals/modifyMainTask";
import AddSubTask from "../../Common/SubTaskModals/addSubTask";
import MainTaskDelete from "../../Common/TasksModals/deleteMainTask";
import MainTaskVerify from "../../Common/TaskVerify/mainTaskVerify";
import AddToSprint from "../Backlogs/addToSprint";
import ChangeUserstoryStatus from "../Modules/changeUserstoryStatus";
import RoadBlock from "../../Common/RoadBlock";
// import { getAllTaskMessages } from "../Modules/network";
import { getAllUserStoryMessages } from "../Modules/Services/getAllUserStoryMessages";
import { Box, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import './consolidateTodo.scss';
import { isMobile } from 'react-device-detect';
import DelegateTask from "../../Common/TasksModals/delegateTask";
import Alert from "../../Common/Alert";
import { MESSAGE_REFRESH_TIMER } from "../../Common/commonUsage";
import RootLoader from "../../Common/Loader/RootLoader";
import { addToSprintORKanban } from "./Services/addToSprintORKanban";

export default function ConsolidatedTodoList() {
    const getUser = useSelector(state => state.auth);
    const dispatch1 = useDispatch();
    const [state, dispatch] = useReducer(consolidatedToDoReducer, initialState)
    const [chatState, dispatch2] = useReducer(moduleReducer, chatInitialState)
    const [searchWord, setSearchWord] = useState('')
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [value, setValue] = React.useState(0);
    const [switchTabs, setSwitchTabs] = React.useState(0);
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState(0);
    // eslint-disable-next-line no-unused-vars

    const handleChange = (newValue) => {
        setSearchWord('')
        setValue(newValue);
        handleTabClick(newValue)
    };

    const priorityColorCodes = ['#3DD896', '#FA8072', '#0BDA51', '#FFAA33', '#4682B4'];

    const [redirections, setRedirections] = useState({ action: null, redirect: false, status: null });
    const { ACTIVE_SPRINT, CONSOLIDATED_TODO, MAINTASKNAME, PRIVATE_TODO, PRIORITY_LEVEL, CHAT, ADD_TO_EPIC,
        MANAGEPROJECTS, PRODUCT_OWNER, SCRUM_MASTER, NEWSUBTASK, EDIT, ADD_TO_SPRINT, MAINTASK, VIEWSUBTASKS, KANBAN, role_array,
        AGILE_PROJECT_NAME, PROJECTNAME, ADD_PRIVATE_TODO, ACTION_ICON, MODULES, PENDING_SUBTASKS, ROADBLOCKS, KANBAN_NAME,
        TASKS_WARNING, ROADBLOCK_WARNING, WORKFLOW, WFT, ROADBLOCK, LIMITED_ACCESS_CONTRIBUTOR } = useWebProperties();
    // const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    var date = moment();
    var currentDate = date.format('YYYY-MM-D');
    const pst = currentDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    useEffect(() => {
        getAllUserStoryMessages(dispatch2, getUser.user);
        getConsolidatedTodoTo(dispatch, getUser.user);
        getConsolidatedTodoBy(dispatch, getUser.user);
        getPrivateTodo(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const fetchUserStoryMessages = () => {
            getAllUserStoryMessages(dispatch2, getUser.user);
        };

        // Function to fetch messages and schedule the next fetch after 10 seconds
        const fetchDataAndScheduleNext = () => {
            fetchUserStoryMessages();
            setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
        };

        // Initial fetch when component mounts
        fetchUserStoryMessages();

        // Schedule the next fetch after 10 seconds
        const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

        // Clean up the timer on component unmount
        return () => clearTimeout(timerId);
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER || role_array[getUser.user.role] === LIMITED_ACCESS_CONTRIBUTOR) {
            setValue(1)
        } else {
            setValue(0)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.consolidatedToDoTo.length >= 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                })
            })
        }
    }, [state.consolidatedToDoTo])

    const SquadChangeStatus = async (project_id, project_name, sprintActiveStatus, action) => {
        try {
            const response = await API.post("squad_login.php", {
                username: getUser.user.empId,
                project_id: project_id,
                corp: project_name,
            }, {}, false);
            if (response.status === 'TRUE') {
                setToken('auth', response.jwt) //store the token information  with exp
                const tokenDetails = jwt_decode(response.jwt);
                setRoleCount('roleCount', tokenDetails.data.roleCount)
                dispatch1(setCurrentUser(tokenDetails.data)); //store the user information
                setRedirections({ action, redirect: true, status: sprintActiveStatus })//checking for Active sprint or not to redirects to sprint board/backlogs board
            }
        } catch (error) {
            // UpdateUserSquad(getUser.user.corp)
        }
        // dispatch(isLoaded());
    }

    const getMessagesCount = (data, msg, task, empId, followUp = null) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.us_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        //for follow up check
        if (followUp === 'Follow Up') {
            return <div style={{ backgroundColor: 'teal', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Follow Up"
                onClick={(event) => handleChatOpen("maintask", task, msgCount, followUp)}>{followUp}</div>
        }

        return (
            <i>
                {msgCount.length > 0 ?
                    <div style={{ display: 'flex', marginRight: '-8px' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                        <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length}</span>
                    </div>
                    :
                    <div style={{ display: 'flex' }}>
                        <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
                            onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
                    </div>}
            </i>
        )
    };

    const getOnlyCount = (data, msg, task, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.us_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return msgCount.length
    };

    const handleChatOpen = (action, index, sno, followUp = null) => {
        var info;
        setOpen({ status: true, action: action });
        if (action === "maintask") {
            info = {
                action: action, id: index.us_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id, followUp: followUp
            }
        }
        setCardInfo(info);
    }

    const handleChatClose = () => {
        setOpen({ status: false, index: 0 })
        getAllUserStoryMessages(dispatch2, getUser.user);
    };

    const handleOpen = (action, index, data) => {
        var info
        setOpen({ status: true, index: index, action: action });
        if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                main_task_id: index.main_task_id,
                view: view_status,
                us_id: index.us_id,
                projectName: index.epic_name, //epic/module name
                epicId: index.epic_id,
                moduleName: index.sprint_desc,
                moduleId: index.sprint_id,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                createdDate: index.created_date,
                taskProgress: index.story_status,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptance_criteria,
                assignedTo: index.assignedto,
                assignedBy: index.assignedby,
                completedDate: index.modified_date,
                completedStatus: index.complete_status,
                targetDate: index.target_date,
                device_id: index.device_id,
                project_name: index.project_name,
            }
        }
        else if (action === "addToProject") {
            var status = "backlog_addUser"
            info = { view: status, data: data }
        }
        else if (action === "addUserStory") {
            info = { view: action, data: data }
        } else if (action === "modify") {
            status = "modify"
            info = { view: status, data: data }
        }
        else if (action === "delete") {
            status = "delete"
            info = { view: status, data: data }
        }
        else if (action === "complete") {
            status = "complete"
            info = { view: status, data: data }
        } else if (action === "private_todo_taskInfo") {
            view_status = "private_todo_taskInfo"
            info = {
                view: view_status,
                pt_id: index.pt_id,
                priority_level: index.priority_level,
                title: index.story_title,
                description: index.story_desc,
                storyPoints: index.story_points,
                acceptanceCriteria: index.acceptance_criteria,
                assignedBy: index.username,
                personal_todo_status: index.personal_todo_status,
            }
        } else if (action === "addSubtask") {
            info = {
                mainTaskId: index.story_id,
                action: action, moduleId: index.sprint_id,
                ideaId: index.epic_id,
                assignedTo: index.assignedTo,
                us_id: index.us_id,
                title: index.story_title,
                projectName: index.project_name,
                template_story_id: index.template_story_id,
                is_workflow: index.is_workflow,
                project_id: index.project_id
            }
        }
        else if (action === "modifyTask" || action === 'delegate') {
            info = {
                moduleId: index.sprint_id,
                moduleName: index.sprint_desc,
                priorityLevel: index.priority_level,
                projectId: index.project_id,
                ideaId: index.epic_id,
                projectName: index.epic_name,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                acceptanceCriteria: index.acceptance_criteria,
                storyPoints: index.story_points,
                userDetails: index.assignedto,
                id: index.assignedTo,
                targetDate: index.target_date,
                story_type: index.story_type,
                kanban_status: index.kanban_status,
                tasks: index,
            }
        }
        else if (action === "add") {
            status = "backlog_addUser"
            info = {
                view: status,
            }
        }
        else if (action === "changeUserstoryStatus") {
            info = {
                action: action,
                inprogress: data.inprogressStoryId,
                todo: data.todoStoryId,
                currentSprint: data.currentSprint,
                assignedTo: data.assignedTo,
                projectName: data.projectName,
                story_title: data.story_title,
                inprogress_story_title: data.inprogress_story_title,
                us_id: data.us_id,
                todo_us_id: data.todo_us_id,
                toDoProjectName: data.toDoProjectName
            }
        }
        else if (action === "add_to_epic") {
            var epic_status = "epic"
            info = {
                us_id: index.us_id,
                view: epic_status,
                projectid: index.project_id,
                taskId: index.story_id,
                project_id: index.project_id,
            }
        }
        else if (action === "current_add_to_sprint") {
            info = {
                project_id: index.project_id,
                us_id: index.us_id,
                projectid: index.project_id,
                taskId: index.story_id,
                // currentDate: data.currentDate,
                assignedTo: index.assignedto,
                assign_to: index.assignedTo,
                title: index.story_title,
                sprintDesc: index.sprint_desc,
                // device_id: index.device_id,
                // player_id: index.player_id,
                storyPoints: index.story_points,
            }
        }
        else if (action === "Delete") {
            info = {
                projectName: index.projectitle,
                title: index.story_title,
                description: index.story_desc,
                taskId: index.story_id,
                moduleId: index.sprint_id,
                action: action
            }
        } else if (action === "movetobacklog") {
            let message = `${MAINTASK} is blocked`;
            info = {
                taskId: index.story_id,
                message: message,
                project_id: index.project_id,
                sprintId: index.sprint_id,
                kanban_status: index.kanban_status === "1" ? "1" : "0"
            }
        }
        setCardInfo(info)
    };

    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getConsolidatedTodoTo(dispatch, getUser.user);
        getConsolidatedTodoBy(dispatch, getUser.user);
        getPrivateTodo(dispatch, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 })
    };

    const handleSwitchTabs = async (tabIndex) => {
        await setSwitchTabs(tabIndex);
    };

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const { action, redirect, status } = redirections;
    if (redirect) {
        if ((getRoleCount('roleCount') >= 1) && (action === "sprints" || action === "project")) {
            return status === ACTIVE_SPRINT ? <Redirect to={`/${MODULES}`} />
                : status === KANBAN ? <Redirect to={`/${KANBAN_NAME}`} /> : <Redirect to={`/${PENDING_SUBTASKS}`} />
        } else if ((getRoleCount('roleCount') >= 1) && action === "epic") {
            return <Redirect to={`/user/${MANAGEPROJECTS}`} />
        } else if ((getRoleCount('roleCount') >= 1)) {
            return <Redirect to={`/${MODULES}`} />
        } else {
            return <Redirect to='/' />
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box pt={1}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const BlockedTask = `You can't start this because this ${MAINTASK} is in ${ROADBLOCK} List`;

    function renderData(array) {
        return <>
            <table className="tbl arc">
                <tbody>
                    {/* eslint-disable-next-line */}
                    {array.length > 0 ? array.filter((val) => {
                        if (searchWord === "") {
                            return val
                        } else if (`${getSubStringId(val.project_name, 3)}-${val.us_id}-${val.story_title}`.toLowerCase().includes(searchWord.toLowerCase()) || (val.blocked_id !== null && val.userStoryId.includes(searchWord))) {
                            return val
                        }
                    }).map((todo, index) => {
                        const { assignedby, assignedto, main_task_id, target_date, created_date, complete_status, project_name, sprint_target_date, us_id, story_title, project_id, active_status, priority_level, epic_name, story_id, sprint_id, assignedTo, kanban_status, role, template_story_id, status, is_workflow, task_count, story_type, userStoryId, blocked_id } = todo;
                        let sprintActiveStatus = kanban_status === "1" && sprint_target_date === "0001-01-01" ? KANBAN : new Date(sprint_target_date) >= new Date(pst) ? ACTIVE_SPRINT : PENDING_SUBTASKS;
                        let badgeColor = kanban_status === "1" && sprint_target_date === "0001-01-01" ? '#ADD8E6' : sprintActiveStatus === ACTIVE_SPRINT ? '#8F00FF' : '#E37066';
                        const accessRole = (role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER);//project/user story wise user role
                        let SPRINT_OR_KANBAN_WARNING = `You can't start this Task because of not having ${ACTIVE_SPRINT} Board`;

                        return (
                            <tr key={index}>
                                <td style={{ border: '1px solid #e1e1e1' }}>
                                    <div className="col-12 td1" style={(created_date > target_date && complete_status === 'pending' && target_date !== '0000-00-00') ? { backgroundColor: '#f58484' } : null}>
                                        <div>
                                            <div class={isMobile ? "col-12" : "d-flex col-12"}>
                                                {
                                                    isMobile &&
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '15%' }}>
                                                            <div style={{ padding: '5px', marginRight: '10px' }}>
                                                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                    {
                                                                        getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                    }
                                                                </button>
                                                            </div>
                                                            <div className="dropdown show" style={{ cursor: 'pointer' }}>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0', marginLeft: '10px' }} />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                    <div>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                        {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                        {(accessRole && epic_name === null) && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                        {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                        {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                        {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>}
                                                                        {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                        {task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>}
                                                                        {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group tasks</Link></button>}
                                                                        {<button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: 780, display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', backgroundColor: 'red', cursor: 'pointer', color: 'white', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                                {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                                {(story_type === 'group') && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={'Group Task'}>{'GR'}</div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: 780, alignItems: 'center', display: isMobile ? "none" : 'flex' }}>
                                                    <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', backgroundColor: 'red', cursor: 'pointer', color: 'white', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                    {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                    {story_type === 'group' && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={'Group Task'}>{"GR"}</div>}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ padding: '5px', marginRight: '5px', marginTop: '2px', float: "right", display: isMobile ? "none" : {} }}>
                                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                            {
                                                                getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                            }
                                                        </button>
                                                    </div>
                                                    {
                                                        value === 2 &&
                                                        <div style={{ backgroundColor: '#000080', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={`Assigned By ${assignedby}`}>{getSubStringId(assignedby, 3)}</div>

                                                    }
                                                    <div style={{ backgroundColor: value === 0 ? '#000080' : '#019BE1', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={value === 0 ? `Assigned By: ${assignedby}` : `Assigned To ${assignedto}`}>{getSubStringId(value === 0 ? assignedby : assignedto, 3)}</div>
                                                    {/* {loading === story_id ? <div style={{ backgroundColor: "grey", width: '90px', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, height: 'fit-content', textAlign: 'center' }}>Loading ... </div> :
                                                    <> */}
                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name)}>Start</div> : null}
                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)}>Complete</div> : null}
                                                    {((sprintActiveStatus === PENDING_SUBTASKS || active_status === '-1') && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => { Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanban(getUser.user, story_id, MAINTASK, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, project_id, target_date, SPRINT_OR_KANBAN_WARNING)) }}>Start</div> : null}
                                                    {/* </>
                                                    } */}
                                                    {value === 1 || value === 2 ? getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId, 'Follow Up')
                                                        : <div style={{ backgroundColor: '#7290ce', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Delegate" onClick={(event) => handleOpen("delegate", todo)}>Delegate</div>}
                                                    {/* <div style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '155px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</div> */}
                                                    {/* Don`t remove these below lines which were related to Epic and Project Names and redirections` */}
                                                    {/* <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</div>
                                                <div style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</div> */}
                                                    <div style={{ backgroundColor: priorityColorCodes[priority_level], cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{priority_level}</div>
                                                    {/* <div style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{story_points}</div> */}
                                                    <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right", display: isMobile ? "none" : {} }}>
                                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                        <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                            <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                        </a>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                            <div>
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                {accessRole && epic_name === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>}
                                                                {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                {task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>}
                                                                {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}
                                                                {<button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : null}
                </tbody>
            </table>
        </>
    }

    function renderAllData(array) {
        return <>
            <table className="tbl arc">
                <tbody>
                    {/* eslint-disable-next-line */}
                    {array.length > 0 ? array.map((todo, index) => {
                        const { assignedby, assignedto, main_task_id, target_date, created_date, complete_status, project_name, sprint_target_date, us_id, story_title, project_id, active_status, priority_level, epic_name, story_id, sprint_id, assignedTo, kanban_status, role, template_story_id, status, is_workflow, task_count, story_type, userStoryId, blocked_id } = todo;
                        let sprintActiveStatus = kanban_status === "1" && sprint_target_date === "0001-01-01" ? KANBAN : new Date(sprint_target_date) >= new Date(pst) ? ACTIVE_SPRINT : PENDING_SUBTASKS;
                        let badgeColor = kanban_status === "1" && sprint_target_date === "0001-01-01" ? '#ADD8E6' : sprintActiveStatus === ACTIVE_SPRINT ? '#8F00FF' : '#E37066';
                        const accessRole = (role_array[role] === SCRUM_MASTER || role_array[role] === PRODUCT_OWNER);//project/user story wise user role
                        let SPRINT_OR_KANBAN_WARNING = `You can't start this Task because of not having ${ACTIVE_SPRINT} Board`;

                        return (
                            <tr key={index}>
                                <td style={{ border: '1px solid #e1e1e1' }}>
                                    <div className="col-12 td1" style={(created_date > target_date && complete_status === 'pending' && target_date !== '0000-00-00') ? { backgroundColor: '#f58484' } : null}>
                                        <div>
                                            <div class={isMobile ? "col-12" : "d-flex col-12"}>
                                                {
                                                    isMobile &&
                                                    <>
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '15%' }}>
                                                            <div style={{ padding: '5px', marginRight: '10px' }}>
                                                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                                    {
                                                                        getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                                    }
                                                                </button>
                                                            </div>
                                                            <div className="dropdown show" style={{ cursor: 'pointer' }}>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0', marginLeft: '10px' }} />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                    <div>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                        {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                        {(accessRole && epic_name === null) && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                        {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                        {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                        {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                        {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>}
                                                                        {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                        {task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>}
                                                                        {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group tasks</Link></button>}
                                                                        {<button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: 780, display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', backgroundColor: 'red', cursor: 'pointer', color: 'white', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                                {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                                {(story_type === 'group') && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={'Group Task'}>{'GR'}</div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div class="p-2" onClick={() => handleOpen("taskInfo", todo)} style={{ width: 780, alignItems: 'center', display: isMobile ? "none" : 'flex' }}>
                                                    {
                                                        status === 'completed' ?
                                                            <del>
                                                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', backgroundColor: 'red', cursor: 'pointer', color: 'white', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>

                                                            </del>
                                                            :
                                                            <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, todo, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(project_name, 3)}{'-'}{main_task_id !== null && main_task_id !== "0" ? `${main_task_id}-` : ''}{us_id}{'-'}{story_title}{blocked_id !== null && <span style={{ width: '100px', backgroundColor: 'red', cursor: 'pointer', color: 'white', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(project_name, 3)}{'-'}{userStoryId}</span>}</p> </b>
                                                    }
                                                    {task_count > 0 && <div style={{ backgroundColor: 'rgb(143, 0, 255)', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '50px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={WORKFLOW}>{WFT}</div>}
                                                    {story_type === 'group' && <div style={{ backgroundColor: '#28D2FC', cursor: 'pointer', color: 'white', margin: `-3px 10px 0px 10px`, width: '70px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={'Group Task'}>{"GR"}</div>}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ padding: '5px', marginRight: '5px', marginTop: '2px', float: "right", display: isMobile ? "none" : {} }}>
                                                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                                            {
                                                                getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId)
                                                            }
                                                        </button>
                                                    </div>
                                                    {
                                                        value === 2 &&
                                                        <div style={{ backgroundColor: '#000080', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={`Assigned By ${assignedby}`}>{getSubStringId(assignedby, 3)}</div>

                                                    }
                                                    <div style={{ backgroundColor: value === 0 ? '#000080' : '#019BE1', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={value === 0 ? `Assigned By: ${assignedby}` : `Assigned To ${assignedto}`}>{getSubStringId(value === 0 ? assignedby : assignedto, 3)}</div>
                                                    {/* {loading === story_id ? <div style={{ backgroundColor: "grey", width: '90px', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, height: 'fit-content', textAlign: 'center' }}>Loading ... </div> :
                                                    <> */}
                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`, project_name)}>Start</div> : null}
                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1' && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Complete</div> : null}
                                                    {((sprintActiveStatus === PENDING_SUBTASKS || active_status === '-1') && value === 0) ? <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => { Alert('warning', active_status === '-1' ? BlockedTask : addToSprintORKanban(getUser.user, story_id, MAINTASK, handleClose, handleOpen, `${MAINTASK} in progress`, project_name, project_id, target_date, SPRINT_OR_KANBAN_WARNING)) }}>Start</div> : null}
                                                    {/* </>
                                                    } */}
                                                    {value === 1 || value === 2 ? getMessagesCount(index, chatState.allMessages, todo, getUser.user.empId, 'Follow Up')
                                                        : <div style={{ backgroundColor: '#7290ce', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content' }} title="Delegate" onClick={(event) => handleOpen("delegate", todo)}>Delegate</div>}
                                                    {/* <div style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '155px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</div> */}
                                                    {/* Don`t remove these below lines which were related to Epic and Project Names and redirections` */}
                                                    {/* <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</div>
                                                <div style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '130px', textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom" title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</div> */}
                                                    <div style={{ backgroundColor: priorityColorCodes[priority_level], cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{priority_level}</div>
                                                    {/* <div style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{story_points}</div> */}
                                                    {
                                                        status !== 'completed' &&
                                                        <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px', float: "right", display: isMobile ? "none" : {} }}>
                                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                            <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false" >
                                                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                            </a>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', position: 'relative' }}>
                                                                <div>
                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: badgeColor, cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={sprintActiveStatus} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "sprints") }} >{sprintActiveStatus}</button>
                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={AGILE_PROJECT_NAME} onClick={() => { SquadChangeStatus(project_id, project_name, sprintActiveStatus, "project") }}>{project_name.substring(0, 15)}</button>
                                                                    {epic_name !== null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#5CDB95', cursor: 'pointer', color: 'white', textAlign: 'center', height: 'fit-content' }} title={PROJECTNAME} onClick={() => { SquadChangeStatus(project_id, project_name, epic_name, "epic") }}>{epic_name === null ? 'NA' : epic_name.substring(0, 15)}</button>}
                                                                    {accessRole && epic_name === null && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#F4A896", color: 'white' }} onClick={(event) => handleOpen("add_to_epic", todo)}>{ADD_TO_EPIC}</button>}
                                                                    {accessRole && (sprintActiveStatus === PENDING_SUBTASKS) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={(event) => handleOpen("current_add_to_sprint", todo)}>{ADD_TO_SPRINT}</button> : null}
                                                                    {/* {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '0') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => getActiveUserStory(getUser.user, story_id, sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Move To Inprogress</button> : null}
                                                                {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#3DD896", color: 'white' }} onClick={() => updateUserStory(getUser.user, story_id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Move To Done</button> : null} */}
                                                                    {(sprintActiveStatus !== PENDING_SUBTASKS && active_status === '1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#ADD8E6", color: 'white' }} onClick={() => handleOpen("movetobacklog", todo)}>Move To {ROADBLOCKS}</button> : null}
                                                                    {accessRole && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modifyTask", todo)}>{EDIT}</button>}
                                                                    {/* <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button> */}
                                                                    {task_count > 0 && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button>}
                                                                    {(story_type === 'group' && main_task_id === '0') && <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewGroupTasks', state: { id: story_id, title: story_title, moduleId: sprint_id, ideaId: project_id, subTasksAssignedTo: assignedTo, us_id: us_id, template_story_id: template_story_id, is_workflow: is_workflow, completeStatus: status, project_id: project_id } }} style={{ color: 'white' }}>View Group task</Link></button>}
                                                                    {<button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", todo)}>{NEWSUBTASK}</button>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : "No Data Found"}
                </tbody>
            </table>
        </>
    }

    // Define the debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    // Define the debounced search function
    const debouncedSearch = debounce((value) => {
        if (value.length > 0) {
            getAllTasks(dispatch, getUser.user, value);
        }
    }, 1000); // 1000 milliseconds debounce time

    // Call the debounced search function when handling search
    const handleSearch = (value) => {
        setSearchWord(value)
        debouncedSearch(value);
    };

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <ul className="nav nav-pills nav-fill" style={{ padding: '10px', gap: '0.8rem' }}>
                            <li>
                                <span style={{ backgroundColor: switchTabs === 0 ? '#00008B' : '#FFFFFF', color: switchTabs === 0 ? '#FFFFFF' : '#00008B', padding: '5px 16px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleSwitchTabs(0)}>{CONSOLIDATED_TODO}</span>
                            </li>
                            <li>
                                <span style={{ backgroundColor: switchTabs === 1 ? '#00008B' : '#FFFFFF', color: switchTabs === 1 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleSwitchTabs(1)}>{PRIVATE_TODO}</span>
                            </li>
                        </ul>
                        {switchTabs === 0 && (
                            <>
                                <div className="justify-content-between row p-2" style={{ alignItems: 'center' }}>
                                    <div className="d-flex mt-1">
                                        <ul className="nav nav-pills nav-fill" style={{ padding: '10px', gap: '0.8rem' }}>
                                            <li>
                                                <span style={{ backgroundColor: value === 0 ? '#00008B' : '#FFFFFF', color: value === 0 ? '#FFFFFF' : '#00008B', padding: '5px 16px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(0)}>Assigned to me</span>
                                            </li>
                                            <li>
                                                <span style={{ backgroundColor: value === 1 ? '#00008B' : '#FFFFFF', color: value === 1 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(1)}>Assigned by me</span>
                                            </li>
                                            <li>
                                                <span style={{ backgroundColor: value === 2 ? '#00008B' : '#FFFFFF', color: value === 2 ? '#FFFFFF' : '#00008B', padding: '5px 10px', border: '1px solid black', cursor: 'pointer' }} onClick={() => handleChange(2)}>All {MAINTASK}s</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex mt-1" >
                                        <div class="input-group-prepend">
                                            <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10, display: isMobile ? 'none' : {} }}>Search: </span>
                                        </div>
                                        {
                                            !isMobile ?
                                                value === 2 ?
                                                    <input type="search" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                        value={searchWord}
                                                        onChange={(event) => handleSearch(event.target.value)}
                                                        placeholder="Search with id or title"
                                                    />
                                                    :
                                                    <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                        value={searchWord}
                                                        onChange={(event) => setSearchWord(event.target.value)}
                                                    />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="justify-content-end row" style={{ marginRight: '32%', display: isMobile ? 'none' : {} }}>
                                        <button style={{ backgroundColor: 'transparent', border: '0', marginBottom: '10%' }} type="button" onClick={() => handleOpen("addUserStory", 0, {})}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                        {open.action === "addUserStory" ?
                                            <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                    </div>
                                </div>
                                {
                                    isMobile &&
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className="d-flex justify-content-end mb-3 mt-2 sgt">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                                </div>
                                                {
                                                    value === 2 ?
                                                        <input type="search" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                            value={searchWord}
                                                            onChange={(event) => handleSearch(event.target.value)}
                                                            placeholder="Search with id or title"
                                                        />
                                                        :
                                                        <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
                                                            value={searchWord}
                                                            onChange={(event) => setSearchWord(event.target.value)}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <div className="justify-content-end row sre">
                                            <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("addUserStory", 0, {})}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                            {open.action === "addUserStory" ?
                                                <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                                /> : null
                                            }
                                        </div>
                                    </>
                                }
                                <div className="col-lg-12 grid-margin stretch-card">
                                    {/* <div className="card"> */}
                                    <div className="card-body" style={{ overflowX: "auto", minHeight: '300px' }}>
                                        <TabPanel value={value} index={0}>
                                            {renderData(state.consolidatedToDoTo)}
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            {renderData(state.consolidatedToDoBy)}
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            {
                                                state.isLoading ?
                                                    <RootLoader />
                                                    :
                                                    searchWord.length > 0 ?
                                                        renderAllData(state.allTasks)
                                                        :
                                                        null
                                            }
                                        </TabPanel>
                                        {
                                            (open.action === "taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "modifyTask" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "delegate" ? <DelegateTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                                <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                                : null}
                                        {
                                            open.action === "Delete" ? <MainTaskDelete open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "Verify" ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            (open.action === "add_to_epic" || open.action === "current_add_to_sprint") ? <AddToSprint open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "movetobacklog" ? <RoadBlock open={open} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null}
                                    </div>
                                    {/* </div> */}
                                </div>
                            </>
                        )}
                        {switchTabs === 1 && (
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="justify-content-between row p-2">
                                            {/* <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 10 }}>{CONSOLIDATED_TODO}</h4> */}
                                            <div className="d-flex justify-content-end mb-3 mt-2">
                                                <div class="input-group-prepend">
                                                    <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                                                </div>
                                                <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '35px' }}
                                                    value={searchWord}
                                                    onChange={(event) => setSearchWord(event.target.value)}
                                                />
                                            </div>
                                            <div className="justify-content-end row" >
                                                <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("add")}> <img src="images/common/add.png" title={ADD_PRIVATE_TODO} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{ADD_PRIVATE_TODO}</span></button>
                                                {
                                                    open.action === "add" ? <AddPrivateToDo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                                    /> : null
                                                }
                                            </div>
                                        </div>

                                        {/* eslint-disable-next-line */}
                                        {state.privateToDo.length > 0 ? state.privateToDo.filter((val) => {
                                            if (searchWord === "") {
                                                return val
                                            } else if (val.story_title.toLowerCase().includes(searchWord.toLowerCase()) || val.us_id.toLowerCase().includes(searchWord.toLowerCase())) {
                                                return val
                                            }
                                        }).map((todo, index) => {
                                            return (
                                                <div className="col-12">
                                                    <div className="card col-12 ">
                                                        <div className="" >
                                                            <div class="d-flex col-12"  >
                                                                <div class="mr-auto p-2" onClick={(event) => handleOpen("private_todo_taskInfo", todo)}>
                                                                    <span style={{ width: '500px', cursor: 'pointer', paddingTop: 10, textTransform: 'capitalize' }} title={MAINTASKNAME}>{todo.status === "pending" ? <p style={{ color: 'black' }}>PT- {todo.pt_id}-{todo.story_title}</p> : <del><p style={{ color: 'black' }}>PT- {todo.pt_id}-{todo.story_title}</p></del>} </span>
                                                                </div>
                                                                {todo.activeStatus === '0' && todo.status === 'pending' && <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => getActiveUserStory(getUser.user, todo.id, todo.sprint_id, handleClose, handleOpen, `${MAINTASK} in progress`)}>Start</div>}
                                                                {todo.activeStatus === '1' && todo.status === 'pending' && <div style={{ backgroundColor: "teal", color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '90px', textAlign: 'center', height: 'fit-content', cursor: 'pointer' }} onClick={() => updateUserStory(getUser.user, todo.id, handleClose, `${MAINTASK} is completed`, TASKS_WARNING, ROADBLOCK_WARNING)}>Complete</div>}
                                                                <p style={{ backgroundColor: priorityColorCodes[todo.priority_level], cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, width: '50px', textAlign: 'center', height: 'fit-content' }} title={PRIORITY_LEVEL}>P:{todo.priority_level}</p>
                                                                {/* <p style={{ backgroundColor: '#6495ED', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, padding: '3px', borderRadius: '70px', width: '25px', textAlign: 'center', height: 'fit-content' }} title={STORY_POINTS}>{todo.story_points}</p> */}
                                                                <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 15, marginRight: 15, padding: '5px', marginTop: 5, float: "right" }}>
                                                                    {/* eslint-disable-next-line */}
                                                                    {todo.status === "pending" ? <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                    </a> :
                                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                    }
                                                                    {todo.status === "pending" &&
                                                                        <div className="dropdown-menu mn" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                            <div>
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }}
                                                                                    onClick={(event) => handleOpen("addToProject", index, todo)}
                                                                                >Add to Project</button>
                                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: '#FFC100', color: 'white' }}
                                                                                    onClick={(event) => handleOpen("modify", index, todo)}
                                                                                >Modify</button>
                                                                                {/* {getUser.user.empId === todo.assigned_by ?
                                                                                    <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: "#76C54E", color: 'white' }}
                                                                                        onClick={(event) => handleOpen("complete", index, todo)}
                                                                                    >Complete</button>
                                                                                    : ""} */}
                                                                                {getUser.user.empId === todo.assigned_by ?
                                                                                    <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                                        onClick={(event) => handleOpen("delete", index, todo)}
                                                                                    >Delete</button>
                                                                                    : ""}
                                                                            </div>
                                                                        </div>
                                                                        // :
                                                                        // <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                        //     <div>
                                                                        //         {getUser.user.empId === todo.assigned_by ?
                                                                        //             <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#FF4747", color: 'white' }}
                                                                        //                 onClick={(event) => handleOpen("delete", index, todo)}
                                                                        //             >Delete</button>
                                                                        //             : ""}
                                                                        //     </div>
                                                                        // </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                            : null}
                                        {
                                            (open.action === "private_todo_taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {open.action === "addToProject" ?
                                            <AddToToDoProjects open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {(open.action === "delete" || open.action === "complete") ?
                                            <DeletePrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {open.action === "modify" ?
                                            <ModifyPrivateToDo open={open.status} data={cardInfo} handleClose={handleClose} handleModalClose={handleModalClose}
                                            /> : null
                                        }
                                        {
                                            open.action === "changeUserstoryStatus" && cardInfo != null ? (
                                                <ChangeUserstoryStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />)
                                                : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}