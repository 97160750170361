import React, { useEffect, useState } from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from '../../Alert';
import API from '../../Network/API';
import { isLoaded, isLoading } from '../../Actions/loading';
// eslint-disable-next-line
import { useWebProperties } from '../../webProperties';
import MainTaskChatBox from '../../ChatMainTask';
import { MESSAGE_REFRESH_TIMER, capitalizeFirstLetter, isValidEmail } from '../../commonUsage';
import { Client_URL } from '../../config';

import Layout from '../../../Optimisation/MetaData/Layout/Layout';

export default function ReleaseBug() {

  const dispatch = useDispatch();
  const loaderStatus = useSelector(state => state.loading.status)
  const webProperties = useSelector(state => state.landingReducer.webProperties)
  const properties = useSelector(state => state.landingReducer.properties)
  const [dataReport, setData] = useState({
    bug_one_sen: "",
    email_to_contact: "",
    steps_for_bug: ""
  })
  // eslint-disable-next-line
  const { APP_NAME, CHAT, IMG_SRC, MESSAGES } = useWebProperties();
  const [buttonClicked, setButtonClicked] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState({ status: false })
  const [cardInfo, setCardInfo] = useState()
  const [pendingTasks, setPendingTasks] = useState([])
  const [searchWord, setSearchWord] = useState('')
  const [allMessages, setAllMessages] = useState([])
  const [empId, setEmpId] = useState('0')
  // eslint-disable-next-line

  useEffect(() => {
    getAllUserStoryMessages();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchUserStoryMessages = () => {
      getAllUserStoryMessages()
    };

    // Function to fetch messages and schedule the next fetch after 10 seconds
    const fetchDataAndScheduleNext = () => {
      fetchUserStoryMessages();
      setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
    };

    // Initial fetch when component mounts
    fetchUserStoryMessages();

    // Schedule the next fetch after 10 seconds
    const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, []);

  async function getAllUserStoryMessages() {
    try {
      var response = await API.post(
        "user_story_chat.php",
        {
          corp_code: 'AgileNexApp',
          action: "getAllTaskMessages",
        },
        {},
        false
      );
      if (response.status === "True") {
        setAllMessages(response.data);
      } else {
        setAllMessages([]);
      }
    } catch (error) {
      Alert("error", error.message);
    }
  }

  const getSupportTicketOut = async (emp_id) => {
    try {
      var response = await API.post("backlogspage.php", {
        "crop": 'AgileNexApp',
        "empId": emp_id,
        "action": "support_ticket_out",
        "role": 'Limited Access Contributor'
      }, {}, false);
      if (response.status === 'true') {
        setPendingTasks(response.data)
      }
      else {
        setPendingTasks([])
      }
    } catch (error) {
      Alert('error', error.message);
    }
  }


  const handleChange = (e) => {
    setErrorMessage(null)
    setData({
      ...dataReport,
      [e.target.name]: e.target.value
    })
  }

  const sendChat = async (story_id) => {
    await API.post("user_story_chat.php", {
      action: "send",
      corp_code: "AgileNexApp",
      groupId: story_id,
      message: `${dataReport.bug_one_sen}`,
      messagedBy: empId,
      msgId: " ",
      groupName: '',
      groupEmail: [],
      reply_id: "",
    }, {}, false);
    let info = { us_id: story_id, action: 'maintask', id: story_id, project_id: "b9594061fe6c271f0ce5cbcdc7ffb8e5", project_name: 'AgileNexApp', messagedBy: empId, outsideApp: true, corp: 'AgileNexApp', empId: empId }
    setCardInfo(info)
    setOpen({ status: true });
  }


  const addMainTaskFromSupportToKanban = async (e) => {
    e.preventDefault();
    setErrorMessage(null)
    setButtonClicked(true)
    dispatch(isLoading())
    const { email_to_contact, bug_one_sen } = dataReport;

    if (!email_to_contact || !bug_one_sen) {
      setButtonClicked(false)
      setErrorMessage('Please fill all mandatory details. This will help us to address your issue promptly and effectively. Thank you.');
      dispatch(isLoaded())
      return; // Do not proceed with the submission
    }
    try {
      var response = await API.post("manage_userstories.php", {
        action: "addMainTaskFromSupportToKanban",
        title: dataReport.bug_one_sen,
        email: dataReport.email_to_contact,
        projectId: "b9594061fe6c271f0ce5cbcdc7ffb8e5",
        appName: APP_NAME,
        story_type: 'support_ticket_out'

      }, {}, false);
      if (response.status === 'True') {
        sendChat(response.story_id)
        setEmpId(response.emp_id)
        setData({
          bug_one_sen: "",
        })
        setButtonClicked(false)
        dispatch(isLoaded())
      }
      else if (response.status === 'false') {
        console.log(response.message)
        setButtonClicked(false)
        dispatch(isLoaded())
      }
    } catch (error) {
      Alert('error', error.message);
      setButtonClicked(false)
      dispatch(isLoaded())

    }

  }

  const handleClose = () => {
    setOpen({ status: false })
    setData({
      bug_one_sen: "",
      email_to_contact: "",
      steps_for_bug: ""
    })
    getSupportTicketOut(empId)
  };

  const handleModalClose = () => {
    setOpen({ status: false });
    getSupportTicketOut(empId)
    setData({
      bug_one_sen: "",
      email_to_contact: "",
      steps_for_bug: ""
    })
  }

  const getSupportEmail = () => {
    setErrorMessage(null)
    if (isValidEmail(dataReport.email_to_contact)) {
      addInProject(dataReport.email_to_contact)
    } else {
      setErrorMessage('Enter Valid Email');
    }
  }

  const getMessagesCount = (data, msg, task) => {
    const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.id).map((messages, i) => {
      // eslint-disable-next-line
      return i, messages
    })
    return (
      <i>
        {msgCount.length > 0 ?
          <div style={{ display: 'flex', marginRight: '-8px' }}>
            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount.length}</span>
          </div>
          :
          <div style={{ display: 'flex' }}>
            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
          </div>}
      </i>
    )
  };

  const handleChatOpen = (action, index, sno, assignedTo) => {
    var info;
    setOpen({ status: true, action: action });
    if (action === "maintask") {
      info = { us_id: index.id, action: 'maintask', id: index.id, project_id: "b9594061fe6c271f0ce5cbcdc7ffb8e5", project_name: 'AgileNexApp', sno: sno, messagedBy: empId, outsideApp: true, corp: 'AgileNexApp', empId: empId }
    }
    setCardInfo(info);
    setOpen({ status: true });
  }

  const addInProject = async (email) => {
    var response = await API.post("manage_user.php", {
      fullname: email,
      email: email,
      username: email,
      mobile: '1234567890',
      action: "check_user",
      corp: "AgileNexApp",
      projectId: "b9594061fe6c271f0ce5cbcdc7ffb8e5",
      password: "agile24x7", //state.password.value,
      userType: "Limited Access Contributor",
      user_status: "Active",
      created_by: "",
      created_name: "", //logged in user name
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    if (response.status === "True") {
      setEmpId(response.emp_id)
      getSupportTicketOut(response.emp_id)
      setButtonClicked(false)
    }
    if (response.status === "False") {
      setEmpId(response.emp_id)
      getSupportTicketOut(response.emp_id)
      setButtonClicked(false)
    }
  }


  return (
    <Layout pageName='bugReport'>   
    <div>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          {/* eslint-disable-next-line */}
          <a className="navbar-brand"><Link to={{ pathname: "/" }}>
            {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
            <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

          </a>
          <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
            {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
            {/* eslint-disable-next-line */}
            <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
            {/* eslint-disable-next-line */}
            <a style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
            {/* support page button */}
          </form>
        </div>
      </nav>

      <section id="section1">
        <div className="container">
          <div className="release-note mt-1">
            <div className="d-flex bd-highlight"> <div className="p-1 flex-grow-1 bd-highlight"><h2>Support 24x7</h2></div>  </div>
          </div>

        </div>
      </section>

      {/* collapse view */}
      <div className="container mt-1">
        <div className="accordion ctn" id="accordionExample">

          <div className="card">
            {/*  */}
            {/* If Answer is NO, . */}
            <form onSubmit={(e) => addMainTaskFromSupportToKanban(e)}>
              <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body">
                  <div className="releasenote-cardbody">
                    <div className="card-title text-capitalize mt-3 mb-3"><h6>which email should we contact you at?<span style={{ color: "red" }} >*</span></h6></div>
                    <div class="form-group" style={{ height: 'auto' }}>
                      <input type="email" onBlur={() => getSupportEmail()} value={dataReport?.email_to_contact} onChange={e => handleChange(e)} class="form-control" id="title" name="email_to_contact" placeholder="Enter email" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-2px' }} required />
                    </div>
                  </div>
                </div>
              </div>

              <div id="collapseZero" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div className="card-body" style={{ paddingTop: "0px" }}>
                  <div className="releasenote-cardbody">
                    <div className="card-title text-capitalize mt-3 mb-3"><h6 style={{whiteSpace:'pre-wrap'}}>Could You Please Describe The Issue In One Sentence?<span style={{ color: "red" }} >*</span></h6></div>
                    <div className="col-12 row">
                      <div className="col-10 form-group" style={{ height: 'auto' }}>
                        <input type="text" onChange={e => handleChange(e)} className="form-control" id="title" name="bug_one_sen"
                          value={dataReport?.bug_one_sen} placeholder="Type your issue" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-2px' }} />
                      </div>
                      <div className='col-2'>
                        <button type="submit" disabled={buttonClicked} style={{ background: 'transparent', border: 'none' }}>
                          <img src="images/common/chat.svg" title={MESSAGES} alt="logo" style={{ width: '50px', cursor: 'pointer' }} />
                          <h6>Let’s Chat </h6>
                        </button>
                      </div>
                    </div>
                    {loaderStatus && <div style={{ color: 'green', fontSize: '15px' }}>We are connecting to our support team, please wait....</div>}
                    {errorMessage && <div style={{ color: 'red', fontSize: '15px' }}>{errorMessage}</div>}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <br />
          {
            pendingTasks.length > 0 &&
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body" style={{ overflowX: "auto" }}>
                  <div className="justify-content-between row">
                    <h4 className="card-title" style={{ paddingLeft: 10, paddingTop: 10 }}>Previous Tickets</h4>
                    <div className="d-flex justify-content-end mb-3 mt-2">
                      <div class="input-group-prepend">
                        <span class="text" style={{ color: 'black', marginTop: '3px', fontSize: '13px', paddingRight: 10 }}>Search: </span>
                      </div>
                      <input type="text" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '35px' }}
                        onChange={(event) => setSearchWord(event.target.value)}
                      />
                    </div>
                  </div>
                  {/* eslint-disable-next-line */}
                  <table>
                    <tbody>
                      {/* eslint-disable-next-line */}
                      {pendingTasks.length > 0 ? pendingTasks.filter((val) => {
                        if (searchWord === "") {
                          return val
                        } else if (val.tasktitle.toLowerCase().includes(searchWord.toLowerCase()) || val.id.toLowerCase().includes(searchWord.toLowerCase()) || val.completeStatus.toLowerCase().includes(searchWord.toLowerCase())) {
                          return val
                        }
                      }).map((todo, index) => {
                        const { id, tasktitle, completeStatus } = todo;
                        return (
                          <tr key={index}>
                            <td style={{ border: '1px solid #e1e1e1' }}>
                              <div className="col-12 td1">
                                <div className="col-12" >
                                  <div class="d-flex col-12">
                                    <div class="p-2" style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                                      <b style={{ cursor: 'pointer' }} > <p style={{ color: 'black', textTransform: 'capitalize' }}>AG{'-'}{id}{'-'}{tasktitle}</p> </b>
                                    </div>
                                    <div style={{ width: '20%', backgroundColor: completeStatus === 'pending' ? '#e8e35f' : '#6fe2ac', cursor: 'pointer', color: 'white', marginLeft: 5, padding: '3px', marginTop: 5, marginBottom: 5, textAlign: 'center', height: 'fit-content' }} data-toggle="tooltip" data-placement="bottom"  >{capitalizeFirstLetter(completeStatus)}</div>
                                    <div style={{ width: '10%', padding: '5px', marginLeft: '5px', marginTop: '2px', textAlign: "end" }}>
                                      <button type="button" style={{ backgroundColor: 'transparent', border: "0", padding: "0" }} >
                                        {
                                          getMessagesCount(index, allMessages, todo)
                                        }
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      }) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {
        open.status &&
        <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} />
      }

      {/* End collapse view */}



      <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <div style={{ color: 'white' }}>
          {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
        </div>
      </footer>
    </div>
    </Layout>
  )
}
