import React, { useEffect, useState } from 'react';
// import Header from '../TopNav';
import SideNavigation from '../SideNav';
import { getToken, setToken } from '../LocalStorage';
import TopNavWithOutProject from '../../UserModule/Utility/TopNav';
import API from '../Network/API';
import { useWebProperties } from '../webProperties';
import { getSubStringId } from '../SubStringConvert';
import { useSelector } from 'react-redux';
import MainTaskChatBox from '../ChatMainTask';
import MainTaskInfo from '../TasksModals/mainTaskInfo';

export default function ViewGroupTask(props) {

    const [tasksData, setTasksData] = useState([])
    const getUser = useSelector(state => state.auth)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [allMessages, setAllMessages] = useState([])

    var data = {
        id: props?.location?.state?.id || getToken('task_id'),
        title: props?.location?.state?.title || getToken('task_title'),
        moduleId: props?.location?.state?.moduleId || getToken('moduleId'),
        ideaId: props?.location?.state?.ideaId || getToken('ideaId'),
        assignedTo: props?.location?.state?.subTasksAssignedTo || getToken('assignedTo'),
        targetDate: props?.location?.state?.targetDate || getToken('targetDate'),
        us_id: props?.location?.state?.us_id || getToken('us_id'),
        is_workflow: props?.location?.state?.is_workflow || getToken('is_workflow'),
        template_story_id: props?.location?.state?.template_story_id || getToken('template_story_id'),
        completeStatus: props?.location?.state?.completeStatus || getToken('completeStatus'),
    }
    // console.log(props,data.assignedTo, props.location.state.subTasksAssignedTo)
    setToken('task_id', data.id)
    setToken('task_title', data.title)
    setToken('moduleId', data.moduleId)
    setToken('ideaId', data.ideaId)
    setToken('assignedTo', data.assignedTo)
    setToken('targetDate', data.targetDate)
    setToken('us_id', data.us_id)
    setToken('is_workflow', data.is_workflow)
    setToken('template_story_id', data.template_story_id)
    setToken('completeStatus', data.completeStatus)

    const { MAINTASK, CHAT } = useWebProperties();


    useEffect(() => {
        getSubGroupTasks()
        getAllTaskMessages()
        // eslint-disable-next-line
    }, [])

    function getSubGroupTasks() {
        API.post("getUpdateSprint.php", {
            story_us_id: data.us_id,
            action: "get_group_task_info",
        })
            .then(function (response) {
                if (response.status === 'True') {
                    setTasksData(response.data)
                } else {
                    console.error("Response status is not 'True'.");
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    async function getAllTaskMessages() {
        try {
            var response = await API.post("user_story_chat.php", {
                corp_code: getUser.user.corp,
                action: "getAllTaskMessages",
                "projectId": getUser.user.projectId
            }, {}, false);
            if (response.status === 'True') {
                setAllMessages(response.data);
            } else {
                setAllMessages([])
            }
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const getMessagesCount = (id, empId, data) => {
        const msgCount = allMessages.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {/* {msgCount.length > 0 ? msgCount.length : null} */}
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", data, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length}</span>
                        </div>
                        // <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', backgroundColor: 'green' }} onClick={(event) => handleOpen("maintask", id, msgCount)} />
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", data, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpen = (action, data, sno) => {
        var info
        if (action === 'maintask') {
            info = {
                us_id: data.us_id, action: action, id: data.us_id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type
            }
        }
        if (action === "taskInfo") {
            var view_status = "taskInfo"
            info = {
                us_id: data.us_id,
                view: view_status,
                projectName: data.projectitle,
                epicId: data.ideano,
                moduleName: data.moduletitle,
                moduleId: data.userstoryModuleId,
                title: data.story_title,
                description: data.taskdescription,
                taskId: data.userstory_id,
                createdDate: data.assignedon,
                taskProgress: data.story_status,
                storyPoints: data.story_points,
                acceptanceCriteria: data.acceptanceCriteria,
                assignedTo: data.fullname,
                assignedBy: data.assignby,
                completedDate: data.modifiedDate,
                completedStatus: data.completeStatus,
                targetDate: data.target_date,
                device_id: data.device_id,
                player_id: data.player_id,
                main_task_id: data.main_task_id,
                story_type: data.story_type
            }
        }
        setCardInfo(info)
        setOpen({ status: true, action: action })

    }

    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getAllTaskMessages()

    };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 })
        getAllTaskMessages()
    };

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideNavigation />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="row">
                                            <h4 className="card-title">GROUP {MAINTASK}</h4>
                                            <h4 className="card-title text-success">: {`${getSubStringId(getUser.user.corp, 3)}-${data.us_id}-${data.title}`}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {tasksData.map((item, index) => (
                                        <div className="card col-12" key={index}>
                                            <div className="d-flex col-12" style={{ borderWidth: 2, borderColor: 'red' }}>
                                                <div className="d-flex align-items-center" style={{ flex: 1, justifyContent: 'space-between' }}>
                                                    <b style={{ color: item.complete_status === 'pending' ? 'black' : 'lightgray', cursor: 'pointer' }}
                                                    // onClick={(event) => handleOpen("taskInfo", item)}
                                                    >
                                                        {getSubStringId(getUser.user.corp, 3)}-
                                                        {item.main_task_id !== null && item.main_task_id !== "0" ? `${item.main_task_id}-` : ''}
                                                        {item.us_id}-{item.story_title}
                                                    </b>
                                                    {item.story_type === "group" &&
                                                        <p style={{ backgroundColor: 'rgb(40, 210, 252)', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">GR</p>
                                                    }
                                                    <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, borderRadius: '90px', fontSize: '15px', width: '40px', textAlign: 'center', }} data-toggle="tooltip" data-placement="bottom" title={item.fullname}>{item.assignt_to === 'NA' ? 'NA' : getSubStringId(item.fullname, 1)}</div>
                                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '10px', padding: "0" }}>
                                                        {getMessagesCount(item.us_id, getUser.user.empId, item)}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        {
                            open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === 'maintask' ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}