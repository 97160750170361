// common/services/modifyPrivateToDo.js  (UPDATE)
import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function modifyPrivateToDo(state, dispatch, getUser,handleClose,pt_id, MAINTASK_MODIFY) {
  dispatch(isLoading());
   if (state.taskTitle.value.trim() !== "" ) {
    try {
      var response = await API.post("personal_tasks.php", {
        action: "personal_todo_modify",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected,
        priorityLevel: state.prioritySelected,
        pt_id:pt_id  
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", MAINTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}