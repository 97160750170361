import React, { useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import $ from 'jquery';
import { getToBeAssignedRoadBlocks } from "./Services/getToBeAssignedRoadBlocks";
import { getAllTaskMessages } from "./Services/getAllTaskMessages";
import RootLoader from "../../Common/Loader/RootLoader";
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from "../../Common/webProperties";
import TaskInfo from "../UserDashboard/taskInfo";
import ChangeStatus from "../Backlogs/changeStatus";
import ModifyMainTask from "../../Common/TasksModals/modifyMainTask";
import MainTaskInfo from "../../Common/TasksModals/mainTaskInfo";
import MainTaskChatBox from "../../Common/ChatMainTask";
import convertPSTtoLocalTime from "../../Common/convertPSTtoLocalTime";
import { roadBlockReducer, initialState } from "./roadBlockReducer";
import AddMainTask from "../../Common/TasksModals/addMainTask";
import AddMainTaskToKanban from "../../Common/TasksModals/addMainTaskToKanban";
import API from "../../Common/Network/API";

export default function BlockedList(props) {
    const getUser = useSelector(state => state.auth)
    const [state, dispatch1] = useReducer(roadBlockReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const { MAINTASK, SEVERITY, PRIORITY, ACTION, MAINTASK_DESCRIPTION, EDIT, CHANGE_STATUS, REQUESTED_BY,
        ROADBLOCK, ACTION_ICON, VIEW_DETAILS, CHAT, ROADBLOCK_DATE, ROADBLOCKS } = useWebProperties();
    const [selectedRoadblocks, setSelectedRoadblocks] = useState([]);
    const [activeSprint, setActiveSprint] = useState()
    useEffect(() => {
        getToBeAssignedRoadBlocks(dispatch1, getUser.user);
        getAllTaskMessages(dispatch1, getUser.user);
        // eslint-disable-next-line
    }, [props.flag])

    useEffect(() => {
        API.post(
            "getUpdateSprint.php",
            {
                crop: getUser.user.corp,
                action: "getKanbanList",
                projectId: getUser.user.projectId,
            },
            {},
            false
        ).then(response => {
            if (response.status === "True") {
                setActiveSprint(response.data[0]);
            }
        })
        // eslint-disable-next-line
    }, [props.flag])

    useEffect(() => {
        if (state.toBeAssigned.length >= 0) {
            $(document).ready(function () {
                window.$('#example1').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    order: [[0, 'desc']],
                    columnDefs: [
                        { targets: 0, orderable: false } // Disable sorting for the first column
                    ]
                })
            })
        }
        //  eslint-disable-next-line 
    }, [state.toBeAssigned])
    useEffect(() => {
        if (state.toBeAssigned.length >= 0) {
            $(document).ready(function () {
                window.$('#example2').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    order: [[0, 'desc']],
                    columnDefs: [
                        { targets: 0, orderable: false } // Disable sorting for the first column
                    ]
                })
            })
        }
        //  eslint-disable-next-line 
    }, [state.toBeAssigned])
    const handleOpen = (action, index, sno, us_id) => {
        var info
        setOpen({ status: true, action: action });
        if (action === "taskInfo" || action === "commentInfo") {
            var view_status = action
            info = {
                id: state.toBeAssigned[index].userstory_id,
                us_id: state.toBeAssigned[index].id,
                view: view_status,
                projectName: state.toBeAssigned[index].projectitle,
                epicId: state.toBeAssigned[index].ideano,
                moduleName: state.toBeAssigned[index].moduletitle,
                moduleId: state.toBeAssigned[index].userstoryModuleId,
                title: state.toBeAssigned[index].tasktitle,
                description: state.toBeAssigned[index].taskdescription,
                taskId: state.toBeAssigned[index].userstory_id,
                createdDate: state.toBeAssigned[index].assignedon,
                taskProgress: state.toBeAssigned[index].taskStatus,
                storyPoints: state.toBeAssigned[index].storyPoints,
                acceptanceCriteria: state.toBeAssigned[index].acceptanceCriteria,
                assignedTo: state.toBeAssigned[index].assigntto,
                assignedBy: state.toBeAssigned[index].assignby,
                completedDate: state.toBeAssigned[index].modifiedDate,
                completedStatus: state.toBeAssigned[index].completeStatus,
                targetDate: state.toBeAssigned[index].targettime,
                device_id: state.toBeAssigned[index].device_id,
                player_id: state.toBeAssigned[index].player_id,
                main_task_id: state.toBeAssigned[index].main_task_id,
                story_type: state.toBeAssigned[index].story_type
            }
        } else if (action === "modify") {
            info = {
                us_id: state.toBeAssigned[index].id,
                projectName: state.toBeAssigned[index].projectitle,
                moduleId: state.toBeAssigned[index].userstoryModuleId,
                ideaId: state.toBeAssigned[index].ideano,
                title: state.toBeAssigned[index].tasktitle,
                description: state.toBeAssigned[index].taskdescription,
                taskId: state.toBeAssigned[index].userstory_id,
                acceptanceCriteria: state.toBeAssigned[index].acceptanceCriteria,
                storyPoints: state.toBeAssigned[index].storyPoints,
                userDetails: state.toBeAssigned[index].assigntto,
                id: state.toBeAssigned[index].assignedTo,
                priorityLevel: state.toBeAssigned[index].priorityLevel,
                targetDate: state.toBeAssigned[index].targettime,
                action: action,
                sprintTargetDate: state.toBeAssigned[index].targettime,
                currentDate: state.toBeAssigned[index].currentDate,
                activeStatus: state.toBeAssigned[index].activeStatus,
                backlogs: state.toBeAssigned[index].backlogs,
                device_id: state.toBeAssigned[index].device_id,
                player_id: state.toBeAssigned[index].player_id
            }
        } else if (action === "changeStatus") {
            info = {
                us_id: state.toBeAssigned[index].id, title: state.toBeAssigned[index].tasktitle, action: action, taskId: state.toBeAssigned[index].userstory_id, activeStatus: state.toBeAssigned[index].activeStatus, completeStatus: state.toBeAssigned[index].completeStatus, sprintId: state.toBeAssigned[index].userstoryModuleId, main_task_id: state.toBeAssigned[index].main_task_id,
                story_type: state.toBeAssigned[index].story_type
            }
        }
        else {
            info = {
                us_id: us_id,
                action: action,
                id: us_id,
                sno: sno
            }
        }
        setCardInfo(info)
    };
    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getToBeAssignedRoadBlocks(props.dispatch, getUser.user);
        getAllTaskMessages(dispatch1, getUser.user);
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }
    const getMessagesCount = (data, msg, task, empId, id) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task.id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-8px" }} onClick={(event) => handleOpen("maintask", data, msgCount, id)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-8px" }} onClick={(event) => handleOpen("maintask", data, msgCount, id)} />
                        </div>

                }
            </i>
        )
    }

    // Function to handle checkbox change
    const handleCheckboxChange = (tasks, type) => {
        if (type === 'all') {
            if (selectedRoadblocks.length === tasks.filter(item => {
                return item.completeStatus === 'pending'
            }).length) {
                // If all tasks are already selected, deselect all
                setSelectedRoadblocks([]);
            } else {
                // Otherwise, select all tasks
                setSelectedRoadblocks([...tasks.filter(item => {
                    return item.completeStatus === 'pending'
                })]);
            }
        } else {
            // Handle individual task selection
            const selectedIndex = selectedRoadblocks.findIndex(item => item.id === tasks.id);
            if (selectedIndex === -1) {
                // Task not found, add it to selectedRoadblocks
                setSelectedRoadblocks(prevState => [...prevState, tasks]);
            } else {
                // Task found, remove it from selectedRoadblocks
                const updatedSelectedRoadblocks = [...selectedRoadblocks];
                updatedSelectedRoadblocks.splice(selectedIndex, 1);
                setSelectedRoadblocks(updatedSelectedRoadblocks);
            }
        }
    };

    const handleOpenRoadBlock = (action) => {
        var info
        setOpen({ status: true, action: action });
        if (action === "addRoadblockToTask") {
            info = {
                selectedRoadblocks: selectedRoadblocks,
                activeSprint
            }
        }
        setCardInfo(info)
    }

    return <div className="table-responsive">
        {state.isLoading ? <RootLoader /> :
            <>
                <div className="m-1">
                    <div className="col-lg-12 grid-margin stretch-card">
                        <div className="card" style={{ border: '0px' }}>
                            <div className="card-body">
                                <h4 className="card-title">{ROADBLOCKS} </h4>
                                <div style={{ height: '40px' }}>
                                    {
                                        selectedRoadblocks.length > 0 ?
                                            <button className="btn btn-sm btn-info" onClick={(event) => handleOpenRoadBlock('addRoadblockToTask')}>Combine Tasks</button>
                                            :
                                            null
                                    }
                                </div>
                                {

                                }
                                <table
                                    search="true"
                                    id={props.kanban_status === "1" ? "example2" : "example1"} className="table table-striped table-bordered rwd-table"
                                    style={{ width: '100%', overflow: 'auto' }}
                                    data-pagination="true"
                                >
                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                        <tr>
                                            {
                                                state.toBeAssigned.length > 0 &&
                                                <th><input type="checkbox"
                                                    onChange={() => handleCheckboxChange(state.toBeAssigned, 'all')}
                                                    checked={selectedRoadblocks.length === state.toBeAssigned.filter(item => {
                                                        return item.completeStatus === 'pending'
                                                    }).length && state.toBeAssigned.length > 0}
                                                /></th>
                                            }
                                            <th>Blocking {MAINTASK}</th>
                                            <th>{MAINTASK} Details</th>
                                            <th>Assigned {MAINTASK}</th>
                                            <th>{SEVERITY}</th>
                                            <th>Assigned By</th>
                                            <th>Assigned Date</th>
                                            <th>{CHAT}</th>
                                            <th>{ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            state.toBeAssigned.length > 0 ? state.toBeAssigned.filter(item => {
                                                return item.kanban_status === props.kanban_status
                                            }).map((tasks, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><input type="checkbox"
                                                            disabled={tasks.completeStatus === "completed"}
                                                            checked={selectedRoadblocks.some(selectedTask => selectedTask.id === tasks.id)}
                                                            onChange={() => handleCheckboxChange(tasks, 'single')} /></td>
                                                        <td data-th={`${ROADBLOCK} Description`}>{tasks.completeStatus === "pending" ? <b>{getSubStringId(getUser.user.corp, 3)}-{(tasks.id)}{' '}{(tasks.tasktitle)}</b> : <del>{getSubStringId(getUser.user.corp, 3)}-{(tasks.id)}{' '}{(tasks.tasktitle)}</del>}</td>
                                                        <td data-th={MAINTASK_DESCRIPTION}>{tasks.completeStatus === "pending" ? <><b style={{ cursor: 'pointer', color: 'red' }}>{getSubStringId(getUser.user.corp, 3)}-{(tasks.blocked_userid)}</b><b>{` ${(tasks.blocked_story_title)}`}</b></> : <><del style={{ cursor: 'pointer', color: 'red' }}>{getSubStringId(getUser.user.corp, 3)}-{(tasks.blocked_userid)}</del><del>{` ${(tasks.blocked_story_title)}`}</del></>}</td>
                                                        {
                                                            tasks.road_block_ids !== null ?
                                                                <td data-th={MAINTASK}>{getSubStringId(getUser.user.corp, 3)}-{tasks.road_block_ids}</td>
                                                                :
                                                                <td></td>
                                                        }
                                                        <td data-th={PRIORITY}>{tasks.priorityLevel}</td>
                                                        <td data-th={REQUESTED_BY} style={{ textTransform: "capitalize", width: '130px' }}>{tasks.assignby}</td>
                                                        <td data-th={ROADBLOCK_DATE} style={{ width: '150px' }}>{convertPSTtoLocalTime(tasks.assignedon)}</td>
                                                        <td data-th={CHAT} style={{ width: '8px' }}>
                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", width: '5px', padding: "0", marginLeft: '15px' }} >
                                                                {
                                                                    getMessagesCount(index, state.allMessages, tasks, getUser.user.empId, tasks.id)
                                                                }
                                                            </button>
                                                        </td>
                                                        <td style={{ width: '8px' }}>
                                                            <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, padding: '5px', marginTop: 2 }}>
                                                                {/* eslint-disable-next-line */}
                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                                </a>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                                    <div>
                                                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", index)}>{VIEW_DETAILS}</button>
                                                                        {tasks.completeStatus === 'pending' && <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", index)}>{EDIT}</button>}
                                                                        {(tasks.completeStatus === 'pending' && tasks.activeStatus !== '-1') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("changeStatus", index)}>{CHANGE_STATUS}</button> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {
            open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
            /> : null
        }
        {
            (open.action === "modify") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
            /> : null
        }
        {
            (open.action === "addRoadblockToTask") ?
                props.kanban_status === "0" ?
                    <AddMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} combine_tasks={true} />
                    :
                    <AddMainTaskToKanban open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} combine_tasks={true} />
                //  <AssignRoadblockTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose} kanban_status={props.kanban_status}/>
                : null
        }
        {
            open.action === "changeStatus" ? <ChangeStatus open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
            /> : null
        }
        {
            open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
            /> : null
        }
        {
            open.status && open.action === "commentInfo" ? (
                <TaskInfo
                    open={open.status}
                    handleClose={handleModalClose}
                    data={cardInfo}
                    handleModalClose={handleModalClose}
                />
            ) : null
        }
    </div >
}