import React, { useEffect, useState } from 'react'
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import $ from 'jquery';
import AdminTopNav from '../Utility/TopNav';
import AdminSideBar from '../Utility/SideNav';
import { Modal } from 'react-bootstrap';
import Moment from 'moment';
import UserProjectInfo from './userProjectInfo';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { useWebProperties } from '../../Common/webProperties';
import Select from 'react-select'
import API from '../../Common/Network/API';
import { useSelector } from 'react-redux';
import { removeDuplicatesFromString } from '../../Common/commonUsage';
import { isMobile } from 'react-device-detect';

export default function AgileUsageReports() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [info, setInfo] = useState();
    const getUser = useSelector(state => state.auth)
    const { SELECT_DATE_RANGE, DATE, EMPLOYEE_NAME, ACTIVE_PROJECTS, LOGIN_TIME,
        USAGE_REPORTS, TOTAL_PROJECTS, REGISTERED_DATE, USER_STATUS, PROJECTS_INVOLVED } = useWebProperties();

    const [filter, setFilter] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ])

    const [result, setResult] = useState([])
    const [filterData, setFilterData] = useState([])
    const [groupDetails, setGroupDetails] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        getAdminGroupDetails(getUser.user)
        // eslint-disable-next-line
    }, [])

    // console.log(filterData)
    // console.log(result)

    async function getAdminGroupDetails(getUser) {
        try {
            var response = await API.post("group_chat.php", {
                action: 'getAdminGroupDetails',
                created_by: getUser.userName,
                // projectId: getUser.projectId,
                // "userType": getUser.role,
                // "empId": getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                setGroupDetails(response.data)
            }
            else {
                setGroupDetails([])
            }
        } catch (error) {
            console.log('error', error.message);
        }
    }

    const handleOpen = (index, action) => {
        if (action === "add") {
            setOpen({ status: true, index: index, action: action });
        } else {
            setOpen({ status: true, index: index, action: action });
            // eslint-disable-next-line
            var info = {
                email: result[index].name,
                action: PROJECTS_INVOLVED
            }
            setInfo(info)
        }
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }

    async function Network(ActiveUsersApi, InActiveUsersApi, state) {
        setResult([])
        try {
            var employee = [];
            const response = await ReportsAPI.post(`/${ActiveUsersApi}`, { filter }, {}, false)
            const response1 = await ReportsAPI.post(`/${InActiveUsersApi}`, { filter }, {}, false)
            response.concat(response1).map((x) => {
                return (x.name !== "admin@novisync.com" ? employee.push(x) : null);
            })
            state(employee)
            setFilterData(employee)
        }
        catch (error) {
            setMessage(error.message)
            console.log('error', error.message)
        }
    }
    useEffect(() => {
        Network('userActiveProjects', 'nonLoginUsers', setResult)
        // eslint-disable-next-line
    }, [filter])
    const [message, setMessage] = useState("")
    const [view, setView] = useState(false)

    // useEffect(() => {
    //     if (filterData.length > 0) {
    //         $(document).ready(function () {
    //             window.$('#example').DataTable({
    //                 destroy: true,
    //                 retrieve: true,
    //                 fixedHeader: true,
    //                 "order": [[3, "desc"]]
    //             })
    //         })
    //     }
    // }, [filterData])

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const filteredData = result.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (item.data[0].active_projects !== null && item.data[0].total_projects.toString().includes(searchQuery.toLowerCase())) ||
            (item.data[0].active_projects !== null && item.data[0].active_projects.toString().includes(searchQuery.toLowerCase())) ||
            (item.data[0].last_login_time !== null && item.data[0].last_login_time.toString().includes(searchQuery.toLowerCase())) ||
            (item.data[0].created_date !== null && item.data[0].created_date.toString().includes(searchQuery.toLowerCase())) ||
            item.status.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilterData(filteredData);
    }, [result, searchQuery]);

    const sortedData = filterData.sort((a, b) => {
        const aValue = a.data[0][sortColumn];
        const bValue = b.data[0][sortColumn];
        if (sortDirection === 'asc') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }

        if (column === 'name') {
            setFilterData(prevData => {
                const sortedData = prevData.slice().sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (sortDirection === 'asc') {
                        return nameA.localeCompare(nameB);
                    } else {
                        return nameB.localeCompare(nameA);
                    }
                });
                return sortedData;
            });
        }
    };

    const getSortIcon = (column) => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>
</svg>
            </>
        );
    };

    const csvData = [
        [EMPLOYEE_NAME, TOTAL_PROJECTS, ACTIVE_PROJECTS, LOGIN_TIME, REGISTERED_DATE, USER_STATUS],
        ...result.map(({ name, data, status }) => [
            name,
            data[0].total_projects,
            data[0].active_projects !== null ? data[0].active_projects : 0,
            data[0].last_login_time !== null ? data[0].last_login_time : 0,
            data[0].created_date !== null && data[0].created_date !== "00-00-0000" ? data[0].created_date : 'Not Applicable',
            status
        ]),
    ];

    const handleSelectedGroups = (selectedOption) => {
        if (selectedOption.value === 'All') {
            setFilter(result)
        } else {
            let emailArray = selectedOption.emails.split(',')
            setFilterData(result.filter(obj => emailArray.includes(obj.email)))
        }
    }
   // console.log(result)
    return (
        <div className="container-scroller">
            <AdminTopNav />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">{USAGE_REPORTS}</h4>
                                    <div className="d-flex justify-content-between row">
                                        <div className="d-flex" onClick={handleShow}>
                                            {
                                                <button className="btn btn-success p-2 border" style={{ marginLeft: 10 }} onClick={() => setView(!view)}>{SELECT_DATE_RANGE}</button>
                                            }
                                            {
                                                <div style={{ marginTop: 5 }}>
                                                    {<text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 10 }} className=' text-success'> {DATE}: {(Moment(filter[0].startDate).format('ll'))} - {(Moment(filter[0].endDate).format('ll'))} </text>}

                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex col-lg-4 col-sm-6" >
                                        <Select
                                            className="form-control"
                                            placeholder="Select Group"
                                            options={[
                                                { 'value': 'All', 'label': 'All' },
                                                ...(groupDetails?.map(group => {
                                                    return {
                                                        'value': group.id,
                                                        'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`,
                                                        'emails': group.members_email
                                                    };
                                                }))
                                            ]}
                                            onChange={(selectedOption) => {
                                                handleSelectedGroups(selectedOption)
                                                setSelectedGroupDetails(selectedOption)
                                            }}
                                        />
                                    </div>
                                        <CSVLink className="downloadbtn btn btn-primary " filename={`AgileUsageReports-${new Date().getFullYear()}.csv`} data={csvData}>Export to CSV</CSVLink>
                                    <div className='mt-1 pt' style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? '0' : '70%'}}>
                                        <label className='mr-2 mb-0' htmlFor='searchInput'>Search:</label>
                                        <input type="text" className="form-control" style={{ height: '30px', width: '250px' }} aria-label="Search" aria-describedby="basic-addon2" onChange={handleSearch} value={searchQuery} />
                                    </div>
                                    </div>
                                    {
                                        view &&
                                        (
                                            <div className="col-12 " >
                                                <Modal style={{ textAlign: 'center' }} size="xl" show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <DateRangePicker
                                                            onChange={item => setFilter([item.selection])}
                                                            showSelectionPreview={true}
                                                            moveRangeOnFirstSelection={false}
                                                            months={2}
                                                            ranges={filter}
                                                            direction="horizontal"
                                                        />
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-success" type="button" onClick={() => handleClose()}>Search</button>
                                                    </Modal.Footer>
                                                </Modal>

                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
                                }
                                <div className='p-2'>
                                    <div className='pt-2' />
                                    {result.length !== 0 ?
                                        <div className="table-responsive">
                                            <table
                                                search="true"
                                                id="example" className="table table-striped table-bordered rwd-table"
                                                styel={{ width: '100%', overflow: 'auto' }}
                                                data-pagination="true"
                                            >
                                                <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                    <tr>
                                                        <th onClick={() => handleSort('name')}>{EMPLOYEE_NAME} {getSortIcon('name')}</th>
                                                        <th onClick={() => handleSort('total_projects')}>{TOTAL_PROJECTS} {getSortIcon('total_projects')}</th>
                                                        <th onClick={() => handleSort('active_projects')}>{ACTIVE_PROJECTS} {getSortIcon('active_projects')}</th>
                                                        <th onClick={() => handleSort('last_login_time')}>{LOGIN_TIME} {getSortIcon('last_login_time')}</th>
                                                        <th onClick={() => handleSort('created_date')}>{REGISTERED_DATE} {getSortIcon('created_date')}</th>
                                                        <th onClick={() => handleSort('status')}>{USER_STATUS} {getSortIcon('status')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        filterData.length !== 0 ? filterData?.map((x, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td data-th={EMPLOYEE_NAME} style={{ height: 40, fontWeight: 'bold', color: 'green' }} onClick={(event) => handleOpen(index, "projects")}><Link style={{ height: 40, fontWeight: 'bold', color: 'green' }}>{x.name ? x.name:'Undefined'}</Link> </td>
                                                                    <td data-th={TOTAL_PROJECTS} >{x.data[0].total_projects}</td>
                                                                    {x.data[0].active_projects !== null ? <td data-th={ACTIVE_PROJECTS}>{Math.round(x.data[0].active_projects)} </td> : <td data-th={ACTIVE_PROJECTS}>0</td>}
                                                                    {x.data[0].last_login_time !== null ? <td data-th={LOGIN_TIME}>{x.data[0].last_login_time}</td> : <td data-th={LOGIN_TIME}>0</td>}
                                                                    {(x.data[0].created_date !== null && x.data[0].created_date !== "00-00-0000") ? <td data-th={REGISTERED_DATE}>{x.data[0].created_date}</td> : <td data-th={REGISTERED_DATE}>Not Applicable</td>}
                                                                    {x.status === "active" ? <td className="text-capitalize" style={{ height: 40, fontWeight: 'bold', color: 'blue' }} >{x.status}</td> : <td data-th={USER_STATUS} className="text-capitalize" style={{ height: 40, fontWeight: 'bold', color: 'red' }} >{x.status}</td>}
                                                                </tr>
                                                            )
                                                        }) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        : null}
                                    {
                                        open.action === "projects" ? <UserProjectInfo open={open.status} data={info} handleClose={handleClose} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}