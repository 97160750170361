import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { addCommentUserStory } from "./addCommentUserStory";

export async function updateUserStory(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id) {
  if (story_type === 'group' && main_task_id === '0') {
    callToCheckGroupTask(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)
  } else {
    completeTask(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)
  }
  handleClose();
}

const callToCheckGroupTask = (getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id) => {
  API.post("getUpdateSprint.php", {
    story_us_id: us_id,
    action: "get_group_task_info",
  })
    .then(function (response) {
      if (response.status === 'True') {
        if (response.data.length > 0) {
          let isCompleted = response.data.some(obj => obj.complete_status === "completed")
          if (!isCompleted) {
            handleClose();
            Alert('warning', "You can't update until your dependency group task completed");
          } else {
            completeTask(getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id)
          }
        }
      }
    })
    .catch(function (error) {
      console.error(error);
    });
}

const completeTask = async (getUser, userStoryId, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, story_type, us_id, main_task_id) => {
  try {
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      addCommentUserStory(getUser, userStoryId, message, "2");
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)
    }
  } catch (error) {
    Alert("error", error.message);
  }
}